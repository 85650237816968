@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
  --button-font-size-primary: 1.5rem;
  --input-font-size-primary: 1.2rem;
  --label-font-size-primary: 0.8rem;
  --label-font-size-input: 0.9rem;
  --font-family-primary: 'Montserrat', sans-serif;
  --font-family-secondary: 'PT Mono', monospace;
  --font-family-tertiary: 'Lato', sans-serif;
  --header-font-size: 1.25rem;
  --title-font-size: 2rem;
  --body-font-size: 1rem;
  --caption-font-size: 0.875rem;
}