:root {
  --max-container-width: 600px;
  --max-container-width-lg: 750px;
  --max-modal-width: 560px;
  --brand-header-width: 65%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-secondary);
  font-family: var(--font-family-primary);
  background-color: var(--color-secondary);
  background-image: url('/background-image.svg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// removes number input arrows for ff
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// removes yellow autofill bg in chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}