:root {
  --color-red: #e04446;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: #e5e5e5;
  --color-bunker: #0b0d14;
  --color-input-border: #dbdbdb;
  --color-primary: #7ca363;
  --color-secondary: #fff;
  --color-accent: #ffc62f;
  --color-accent-link: #383fab;
  --color-send: #7ca363;
  --color-text-black: #0b0d14;
  --color-text-black-accent: #142152;
  --color-text-black-accent-light: #838383;
  --color-divider: #d0d0d0;
  --color-background-disabled: #f4f4f4;
  --color-about-divider: #fecd3a;
  --color-about-background: #142152;
  --color-about-list-text: #000;
  --color-text-small: #656565;
  --color-text-small-link: #125d91;
  --color-language-list: #fff;
  --color-selected-language-background: rgba(255, 255, 255, 0.3);
  --color-selected-language: #fff;
  --color-text-variant-legals: #000;
  --color-text-variant-info: #000;
  --color-inverted-button-border: #fecd3a;
  --color-inverted-button-text: #000;
  --color-success-button-border: #fecd3a;
  --color-success-button-background: #fecd3a;
  --color-success-button-text: #000;
  --color-accent-button-text: #fff;
  --color-send-button-text: #fff;
  --color-card-title-text: #000;
  --color-card-error: #ffc4c4;
  --color-card-error-text: #e04446;
  --color-card-success: #2e7d32;
  --color-card-success-text: #e04446;
  --color-card-warning: #ed6c02;
  --color-card-warning-text: #fff;
  --color-kyc-failed-text: #fff;
  --color-promo-primary: #A7E2FF;
  --color-promo-secondary: #53c6ff;
}